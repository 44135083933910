<template>
  <div>
    <Header @keywords="handleInfoFromHeader"></Header>
    <div class="wrapper">
      <div v-if="isMobile === false" style="height: 45vh; height: auto">
        <div class="centerWidth" style="margin: 20px auto">
          <div v-if="ad">
            <a
              :href="ad[trans('url,urlEn')]"
              target="_blank"
              :title="ad[trans('name,titleEn')]"
            >
              <img
                style="width: 100%; height: 200px; vertical-align: top"
                :src="
                  ad[trans('img,imgEn')] ? baseURL + ad[trans('img,imgEn')] : ''
                "
                alt=""
              />
            </a>
          </div>
          <div style="margin-top: 30px">
            <el-tabs v-model="tabActive" @tab-click="handleClick">
              <el-tab-pane
                v-for="item in cates"
                :key="item.id"
                :label="item[trans('title,titleEn')]"
              ></el-tab-pane>
            </el-tabs>
            <!-- <div v-if="dataList.length">
              <div class="paperB">
                <div
                  class="paperBox"
                  v-for="item in dataList"
                  :key="item.id"
                  style="cursor: pointer"
                  @click="toDetail(item.id)"
                >
                  <img
                    :src="item.img ? baseURL + item.img : ''"
                    alt=""
                    style="height: 184px; width: 275px"
                  />
                  <div style="padding-left: 20px; flex: 1">
                    <p class="paperT1 twoLine">{{ item.title }}</p>
                    <p class="paperT2 twoLine">{{ item.digest }}</p>
                    <div
                      style="
                        display: flex;
                        justify-content: space-between;
                        margin-top: 9px;
                      "
                    >
                      <div>
                        <p class="paperPage">
                          {{ $t("report.reportPage") }}:
                          {{ item.page + " " + $t("report.pages") }}
                        </p>
                        <div style="margin-top: 10px">
                          <span
                            class="label-box"
                            v-for="(label, labelIndex) in item[
                              trans('label,labelEn')
                            ]
                              ? item[trans('label,labelEn')].split(',')
                              : []"
                            :key="labelIndex"
                            >{{ label }}</span
                          >
                        </div>
                      </div>
                      <p class="paperLook">{{ $t("report.viewReport") }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pagination-container">
                <el-pagination
                  hide-on-single-page
                  :pager-count="5"
                  layout="prev, pager, next"
                  :page-size="size"
                  @current-change="handleCurrentChange"
                  :total="total"
                  background
                >
                </el-pagination>
              </div>
            </div> -->
            <div v-if="dataList.length">
              <div style="display: flex; flex-wrap: wrap; margin: 10px 0">
                <div class="paperMa" v-for="item in dataList" :key="item.id" @click="toDetail(item.id)">
                  <div class="paperMBg">
                    <img
                      style="width: 100%; height: 100%"
                      :src="item.img ? baseURL + item.img : ''"
                    />
                  </div>
                  <div class="paperMBox">
                    <p class="paperMBoxT1 twoLine">{{ item.title }}</p>
                    <p class="paperMBoxT2 threeLine">{{ item.digest }}</p>
                  </div>
                </div>
              </div>
              <div class="pagination-container">
                <el-pagination
                  hide-on-single-page
                  :pager-count="5"
                  layout="prev, pager, next"
                  :page-size="size"
                  @current-change="handleCurrentChange"
                  :total="total"
                  background
                >
                </el-pagination>
              </div>
            </div>
            <el-empty v-else :description="$t('common.noData')"></el-empty>
          </div>
        </div>
      </div>
      <div v-if="isMobile === true">
        <div class="news1">
          <div v-if="ad">
            <a
              :href="ad.url"
              target="_blank"
              :title="ad[trans('name,titleEn')]"
            >
              <img
                style="width: 100%; height: 120px; vertical-align: top"
                :src="
                  ad[trans('img,imgEn')] ? baseURL + ad[trans('img,imgEn')] : ''
                "
                alt=""
              />
            </a>
          </div>
          <div style="min-height: 74vh; margin: 16px;">
            <el-tabs v-model="tabActive" @tab-click="handleClick">
              <el-tab-pane
                v-for="item in cates"
                :key="item.id"
                :label="item[trans('title,titleEn')]"
              ></el-tab-pane>
            </el-tabs>

            <div v-if="dataList.length">
              <div class="chineseBox" v-for="item in dataList" :key="item.id" style="cursor: pointer" @click="toDetail(item.id)">
                <div class="chineseR">
                  <img
                    :src="item.img ? baseURL + item.img : ''"
                    alt=""/>
                  <div>
                    <p class="reportTitle">{{ item.title }}</p>
                    <div>
                      <p class="chinesePage"><i class="el-icon-document"></i> {{ item.page + " " + $t("report.pages") }}</p>
                      <p class="chineseButton">{{ $t("report.viewReport") }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Pagination v-if="dataList.length" :size="size" @current-change="handleCurrentChange" :total="total"></Pagination>
          <ScrollTop></ScrollTop>
        </div>
      </div>
    </div>

    <LoginTip
      :dialogVisible="dialogVisible"
      @hideDialog="hideDialog"
    ></LoginTip>
    <CancleTip
      :dialogVisible="cancleVisible"
      @hide="hide"
      @confirmDialog="confirmDialog"
    ></CancleTip>
    <Footer v-if="isMobile === false"></Footer>
    <Bottom v-if="isMobile === true">123</Bottom>
    <InfoTip
      :dialogVisibleInfo="dialogVisibleInfo"
      @hideDialog="hideInfoDialog"
    ></InfoTip>
    <AiPop @change="showDialog"></AiPop>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import AiPop from "@/components/AiPop.vue";
import LoginTip from "@/components/LoginTip.vue";
import CancleTip from "@/components/CancleTip.vue";
import InfoTip from "@/components/InfoTip.vue";
// import BottomBanner from "@/components/BottomBanner.vue";
import { shareUrl } from "@/utils/vxshare.js";
import mixins from "@/utils/mixins.js";
import ScrollTop from "@/components/mobile/ScrollTop.vue";
import Bottom from "@/components/mobile/Bottom.vue";
import Pagination from "@/components/mobile/Pagination.vue";

import { getAds, getCates, getReports } from "@/api/index";

export default {
  name: "Science",
  mixins: [mixins],
  components: {
    ScrollTop,
    Bottom,
    // BottomBanner,
    InfoTip,
    LoginTip,
    AiPop,
    Header,
    Footer,
    CancleTip,
    Pagination,
  },
  data() {
    return {
      currentPage: 1,
      totalPages: 20,
      activeName: "first",
      user: this.$store.state.user,
      isMobile: false,
      dialogVisibleInfo: false,
      cancleVisible: false,
      cancleId: null,
      dialogVisible: false,
      change: 0,
      searchKeywords: "",
      seconderyClassfy: [],
      articles: [],
      // size: 9,
      // page: 1,
      // total: 1,
      token: localStorage.getItem("access_token"),
      userId: "",
      languageSelect: "",
      primaryTypeid: "2",

      ad: null,
      cates: [],
      tabActive: "0",
      dataList: [],
      parentId: "",
      cateId: "",
      size: 20,
      page: 1,
      total: 0,
    };
  },
  async created() {
    if (localStorage.getItem("language")) {
      this.languageSelect = localStorage.getItem("language");
    } else {
      this.languageSelect = "zh";
    }
    //   获取新闻二级分类列表
    this.token = localStorage.getItem("access_token");
    this.userId = localStorage.getItem("userId");

    const currentPage = localStorage.getItem("setPage");
    if (currentPage) {
      try {
        const parsedPage = JSON.parse(currentPage);
        console.log(parsedPage);
        if (parsedPage.primaryTypeid === this.primaryTypeid) {
          this.page = parseInt(parsedPage.setPage);
          localStorage.setItem("Title", "");
          localStorage.setItem("setPage", ""); // 清空 localStorage
        } else {
          this.page = 1;
          // console.log(error)
          localStorage.setItem("Title", "");
          localStorage.setItem("setPage", ""); // 清空 localStorage
        }
      } catch (error) {
        console.log(error);
        localStorage.setItem("Title", "");
        localStorage.setItem("setPage", ""); // 清空 localStorage
        // 处理 JSON 解析错误
        this.page = 1; // 或者其他处理逻辑
      }
    } else {
      this.page = 1;
    }

    this.parentId = this.$route.params?.parentId;
    this.cateId = this.$route.params?.cateId;

    this.setTKD();
    await this.getCates();
    this.tabActive = this.cates.findIndex((item) => item.id == this.cateId) + "";

    this.getReports();
    this.getAds();

    // this.classfy();
    // this.getartical();
    // this.handleScroll();

    localStorage.setItem("Title", "");
    let url = window.location.href;
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        title: "油气知识",
        text: "洞悉油气行业变化，体验AI大模型，就来油气知识！",
      };
      shareUrl(shareData);
    }, 1000);
  },
  mounted() {
    // Check if the device width is less than 768px (example for mobile)
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
    this.handleScroll();
  },
  methods: {
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToNextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    async getAds() {
      let res = await getAds({
        positions: "7",
        status: 0,
      });
      this.ad = res.data.find((item) => item.position == 7);
    },
    async getCates() {
      let res = await getCates({
        level: 2,
        parentId: this.parentId,
      });
      this.cates = res.data;
    },
    async getReports() {
      let res = await getReports({
        secondaryType: this.cateId,
        pageNum: this.page,
        pageSize: this.size,
        draftStatus: 0,
        articleFlag: 1,
      });
      this.dataList = res.rows;
      this.total = res.total;
    },
    handleCurrentChange(val) {
      this.page = val;
      this.handleScroll();
      this.getReports();
    },
    handleScroll() {
      // 在这里阻止默认的滚动行为，或者自定义滚动行为
      window.scrollTo(0, 0);
    },
    handleClick() {
      this.page = 1;
      this.cateId = this.cates[this.tabActive].id;
      this.getReports();
    },
    toDetail(id) {
      this.$router.push("/report/" + id);
    },
    setTKD(){
      const map = {
        2: 'reports',
        1: 'journals'
      }
      const type = map[this.parentId]
      let title = this.$t(`${type}.navTitle`);
      let keywords = this.$t(`${type}.navKeywords`);
      let description = this.$t(`${type}.navDescription`);


      document.title = title;
      document.querySelector('meta[property="og:title"]').content = title;

      document.querySelector('meta[property="og:keywords"]').content = keywords
      document.querySelector('meta[property="og:description"]').content = description
    },
    

    hideInfoDialog() {
      this.dialogVisibleInfo = false;
    },
    showDialog(type) {
      this.dialogVisible = type;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    hide() {
      this.cancleVisible = false;
    },
    confirmDialog() {
      this.$axios
        .get("/api/subscribe/delete/" + parseInt(this.cancleId.subscribeId), {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
        })
        .then((res) => {
          this.cancleVisible = false;
          if (this.languageSelect === "zh") {
            this.$notify({
              title: "提示",
              message: "取消成功",
            });
          } else {
            this.$notify.warning({
              title: "Tips",
              message: "Cancel successfully",
              showClose: false,
            });
          }
          if (res.data.msg === "操作成功") {
            this.$set(this.cancleId, "isSubscribe", 0);
          }
          this.classfy();
        })
        .catch((error) => {
          console.log(error);
          if (this.languageSelect === "zh") {
            this.$notify({
              title: "提示",
              message: "取消失败，请稍后再试",
            });
          } else {
            this.$notify.warning({
              title: "Tips",
              message: "Cancellation failed, please try again later",
              showClose: false,
            });
          }
        });
    },
    handleSizeChange(val) {
      this.size = val;
      this.getartical();
    },

    // 详情PDF
    detailPdf(pdf) {
      console.log(pdf);
      localStorage.setItem("Title", pdf.title);

      if (this.user.userId) {
        if (this.$store.state.typeInfo == 0) {
          this.dialogVisibleInfo = true;
        } else {
          this.$router.push({
            path: "/pdfDetail",
            query: {
              currentPage: this.page,
              Typeid: 2,
              filePath: pdf.filePath,
            },
          });
        }
      } else {
        this.dialogVisible = true;
      }
      document.title = localStorage.getItem("Title");
    },
    // 下载
    download(article) {
      if (this.userId) {
        if (this.$store.state.typeInfo == 0) {
          this.dialogVisibleInfo = true;
        } else {
          let pdfUrl = article.filePath;
          if (pdfUrl) {
            let link = document.createElement("a");
            link.href = pdfUrl;
            link.download = article.title;
            link.click();
          } else {
            console.error("文章的PDF链接不存在");
          }
        }
      } else {
        this.dialogVisible = true;
      }
    },
    getFileName(url) {
      let name = "";
      if (url !== null && url !== "") {
        name = url.substring(url.lastIndexOf("/") + 1);
      } else {
        name = "无";
      }
      return name;
    },
    // 关键词
    handleInfoFromHeader(info) {
      this.searchKeywords = info.seachInput;
      this.getartical();
      // console.log("关键词"+this.searchKeywords)
    },
    sendSecondery(index) {
      // 记录被点击的按钮索引
      for (var i = 0; i < this.seconderyClassfy.length; i++) {
        this.seconderyClassfy[i].buttonStyle = false;
      }
      index.buttonStyle = true;
      // console.log('Clicked item at index:', index)
      this.searchKeywords = "";
      this.secondaryTypeid = index.id;
      this.page = 1;
      this.size = 9;
      this.getartical();
    },
    // 订阅
    subscribeThis(sub) {
      console.log(sub);
      if (this.user.userId) {
        if (sub.isSubscribe === 0) {
          this.$axios
            .post(
              "/api/subscribe/add",
              {
                primaryType: sub.parentId,
                secondaryType: sub.id,
                type: "2",
                userId: this.user.userId,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: this.token,
                },
              }
            )
            .then((res) => {
              if (this.languageSelect === "zh") {
                this.$notify({
                  title: "提示",
                  message: "订阅成功",
                });
              } else {
                this.$notify.warning({
                  title: "Tips",
                  message: "Subscribe successfully",
                  showClose: false,
                });
              }

              if (res.data.msg === "操作成功") {
                this.$set(sub, "isSubscribe", 1);
              }
              this.classfy();
              // console.log(res)
            })
            .catch((error) => {
              console.log(error);
              if (this.languageSelect === "zh") {
                this.$notify({
                  title: "提示",
                  message: "取消失败，请稍后再试",
                });
              } else {
                this.$notify.warning({
                  title: "Tips",
                  message: "Cancellation failed, please try again later",
                  showClose: false,
                });
              }
            });
        } else {
          this.cancleId = sub;
          this.cancleVisible = true;
        }

        this.$forceUpdate();
      } else {
        this.dialogVisible = true;
      }
      //
      // console.log(this.seconderyClassfy)
    },
    classfy() {
      this.$axios
        .get("/api/report/category", {
          params: {
            level: 2,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
        })
        .then((res) => {
          console.log("行业报告");
          console.log(res.data.data);
          this.seconderyClassfy = res.data.data; // 将响应中的数据直接赋值给 articles 对象数组
          for (var i = 0; i < this.seconderyClassfy.length; i++) {
            this.seconderyClassfy[i].buttonStyle = false;
            if (
              this.languageSelect === "zh" &&
              this.seconderyClassfy[i].id == 4
            ) {
              this.sendSecondery(this.seconderyClassfy[i]);
            } else if (
              this.languageSelect === "en" &&
              this.seconderyClassfy[i].id == 6
            ) {
              this.sendSecondery(this.seconderyClassfy[i]);
            }
          }

          if (this.$route.query.TypeId) {
            for (var j = 0; j < this.seconderyClassfy.length; j++) {
              if (
                this.seconderyClassfy[j].id ===
                parseInt(this.$route.query.TypeId)
              ) {
                var index = this.seconderyClassfy[j];
                this.sendSecondery(index);
                break;
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //   获取文章
    getartical() {
      this.$axios
        .get("/api/report/list", {
          params: {
            secondaryType: this.secondaryTypeid,
            title: this.searchKeywords,
            pageNum: this.page,
            pageSize: this.size,
            draftStatus: 0,
            articleFlag: 1,
          },
          headers: {
            Authorization: this.token,
          },
        })
        .then((res) => {
          window.scrollTo(0, 0);
          this.total = res.data.total;
          this.articles = res.data.rows; // 将响应中的数据直接赋值给 articles 对象数组
          // 重新生成列表

          this.list();
        })
        .catch((error) => {
          console.log(error);
        });
      // 动态调整页面高度
      this.adjustPageHeight();
    },
    adjustPageHeight() {
      // 使用setTimeout确保Vue有时间更新页面内容
    },
    list() {
      if (this.articles.length === 0) {
        this.isLoading = true;
      } else {
        this.isLoading = false;
        for (var i = 0; i < this.articles.length; i++) {
          // 首图
          this.articles[i].img = this.$utils.baseURL + this.articles[i].img;

          this.articles[i].filePath =
            this.$utils.baseURL + this.articles[i].filePath;

          const currentDate = new Date(this.articles[i].createTime);
          const year = currentDate.getFullYear();
          const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
          const day = ("0" + currentDate.getDate()).slice(-2);
          const formattedDate = `${year}-${month}-${day}`;

          this.articles[i].createTime = formattedDate;
        }
      }
    },
  },
  watch: {
    "$store.state.user"(newValue) {
      this.user = newValue;
    },
  },
};
</script>


<style scoped lang="scss">
.totlePage {
  min-height: 45vh;
  height: auto;
  width: 1350px;
  margin: 0 auto;
}
@media (max-width: 1300px) {
  .totlePage {
    width: 1220px;
    margin: 0 auto;
  }
}
.limitTitLe {
  width: 140px;
  font-size: 18px;
  color: #333333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.author {
  width: 90px;
  color: gray;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.author :hover {
  color: var(--main-color);
}

.paper {
  //border: black 5px solid;
  height: 220px;
  display: flex;
}

.pagination-container {
  position: sticky;
  margin-top: auto;
  bottom: 0;
  width: 99%;
  text-align: center;
  background-color: white;
  padding: 20px;
}

.pdf {
  cursor: pointer; /* 鼠标变成手型 */
  border-radius: 16px;
  display: flex;
  height: 180px;
}

.pdf :hover {
  color: var(--main-color);
}

.logoFdf {
  border-radius: 16px;
  border: 1px solid black;
  margin: 1%;
  width: 115px;
  height: 170px;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.5);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.logoFdf:hover {
  transform: scale(1.1); /* 设置悬停时的放大比例，可以根据需要调整 */
  transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
}

.detail {
  width: 60%;
  margin: 2% 5% 3% 5%;
  font-size: 14px;
}

.author-tooltip {
  margin-top: 15px;
  width: 200px;
  position: relative;
  display: inline-block;
}
.title-tooltip {
  width: 200px;
  position: relative;
  display: inline-block;
}
.tooltip-title {
  visibility: hidden;
  width: 250px;
  background-color: white;
  border: black 1px solid;
  color: #333333;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 0;
  margin-left: -60px; /* 将提示框居中 */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-title::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  border-width: 3px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
  transform: translateX(-50%);
}

.title-tooltip:hover .tooltip-title {
  visibility: visible;
  opacity: 1;
}
.tooltip-content {
  visibility: hidden;
  width: 200px;
  background-color: white;
  border: black 1px solid;
  color: #333333;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: -175%;
  left: 25%;
  margin-left: -60px; /* 将提示框居中 */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-content::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  border-width: 3px;
  border-style: solid;
  border-color: transparent transparent #333 transparent;
  transform: translateX(-50%);
}

.author-tooltip:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
}

.tooltip-trigger {
  color: gray;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.editor-icon {
  width: 20px;
  height: 20px;
  font-size: 14px;
  margin-right: 5px;
}

.buttonStyle11 {
  font-size: 16px;
  color: #333333;
  width: 180px;
  height: 45px;
  border-right: #2c3e50;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  box-shadow: 4px 2px var(--main-color);
}

.buttonStyle21 {
  font-weight: bold;
  font-size: 18px;
  color: var(--main-color);
  width: 180px;
  height: 55px;
  border-right: #2c3e50;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  box-shadow: 4px 2px var(--main-color);
}
.buttonStyle1 {
  font-size: 14px;
  color: #333333;
  width: 100%;
  margin: 5px;
  height: 25px;
  padding: 2px;
  border: none;
  box-shadow: none;
  text-align: center;
  border-radius: 0;
  border-right: #333333 2px solid;
}

.buttonStyle2 {
  font-size: 16px;
  font-weight: bold;
  color: var(--main-color);
  width: 100%;
  height: 25px;
  padding: 2px;
  margin: 5px;
  border-right: #2c3e50;
  border: none;
  border-radius: 5px;
  box-shadow: 4px 2px var(--main-color);
}
li {
  list-style-type: none;
}

.news {
  margin-top: 1%;
  display: flex;
  margin-left: 3.5%;
  width: 93%;
}

.secondery {
  font-weight: bold;
  font-size: 20px;

  color: black;
  width: 180px;
  height: 55px;
  border-right: #2c3e50;
  border: none;
  border-radius: 5px;
  margin-top: 5px;
  //border: black 1px solid;
}
.list1 {
  display: grid;
  // grid-template-columns: repeat(3, 1fr);
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  gap: 20px;
  padding: 20px 10px;
  background-color: white;

  .item {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 18px;
    display: flex;
    justify-content: space-between;
  }
}
.list {
  display: block;
  padding: 2px 2px;
  margin: 5px 0 60px 0;
  overflow-x: hidden;
  background-color: white;
  min-height: 100vh;

  .downLoad {
    width: 60%;
    height: 22px;
    line-height: 22px;
    text-align: center;
    background: #f5f5f5;
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 15px;

    .pagination-container {
      position: sticky;
      margin-top: auto;
      bottom: 0;
      font-size: 14px;
      text-align: center;
      background-color: #fff;
    }
    img {
      width: 14px;
      height: 14px;
      margin-right: 5px;
    }

    img.pdfIconHover {
      display: none;
    }
  }
  .item {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 3px 0;
    margin: 5px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .pdf {
      cursor: pointer; /* 鼠标变成手型 */
      border-radius: 16px;
      display: flex;
      height: 140px;
      font-size: 14px;
    }
    .logoFdf {
      position: absolute;
      border-radius: 16px;
      border: 1px solid black;
      margin: 1% 1% 1% 1%;
      width: 108px;
      font-size: 8px;
      height: 131px;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
    }

    .logoFdf:hover {
      transform: scale(1.1); /* 设置悬停时的放大比例，可以根据需要调整 */
      transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
    }

    .limitTitLe {
      width: 120%;
      font-size: 16px;
      color: #333333;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .limitTitLe:hover {
      color: var(--main-color);
    }

    .detail {
      width: 100%;
      font-size: 14px;
      margin-left: 130px;
    }

    .author-tooltip {
      margin-top: 10px;
      width: 220px;
      position: relative;
      display: inline-block;
    }
    .author[data-v-321ccc00] {
      width: 70%;
      color: gray;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .editor-icon {
      width: 15px;
      height: 15px;
      font-size: 8px;
      margin-right: 5px;
    }
    .tooltip-content {
      visibility: hidden;
      width: 200px;
      background-color: white;
      border: black 1px solid;
      color: #333333;
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;
      position: absolute;
      z-index: 1;
      bottom: -175%;
      left: -5%;
      margin-left: -60px;
      opacity: 0;
      transition: opacity 0.3s;
    }
    .title-tooltip {
      width: 200px;
      position: relative;
      display: inline-block;
    }

    .tooltip-title {
      visibility: hidden;
      width: 75%;
      background-color: white;
      border: black 1px solid;
      color: #333333;
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;
      position: absolute;
      z-index: 1;
      bottom: 125%;
      left: 0;
      margin-left: -60px; /* 将提示框居中 */
      opacity: 0;
      transition: opacity 0.3s;
    }

    .tooltip-title::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 25%;
      border-width: 3px;
      border-style: solid;
      border-color: #333 transparent transparent transparent;
      transform: translateX(-50%);
    }

    .title-tooltip:hover .tooltip-title {
      visibility: visible;
      opacity: 1;
    }
  }
}

.downLoad {
  width: 140px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  background: #f5f5f5;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 25px;

  img {
    width: 14px;
    height: 14px;
    margin-right: 5px;
  }

  img.pdfIconHover {
    display: none;
  }
}

.subscribe1 {
  position: absolute;
  border: none;
  margin: 5px 0 0 145px;
  width: 10px;
  height: 20px;
  background-image: url("../../assets/subscribe.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent; /* 这里设置背景颜色为透明 */
}

.subscribeDeny1 {
  position: absolute;
  border: none;
  margin: 5px 0 0 145px;
  width: 10px;
  height: 20px;
  background-image: url("../../assets/subscribeDeny.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent; /* 这里设置背景颜色为透明 */
}
.subscribe {
  position: absolute;
  background-size: 12px 12px; /* 设置背景图片大小 */
  border: none;
  width: 5px;
  margin: -10px 0 0 0;
  background-image: url("../../assets/subscribe.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent; /* 这里设置背景颜色为透明 */
}

.subscribeDeny {
  position: absolute;
  background-size: 12px 12px; /* 设置背景图片大小 */
  border: none;
  margin: -10px 0 0 -15px;
  background-image: url("../../assets/subscribeDeny.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent; /* 这里设置背景颜色为透明 */
}
.secondery1 {
  width: 100%;
  height: 45px;
  background-color: white;
  display: flex;
  justify-content: space-between; /* 水平分布，两端对齐 */
  align-items: center; /* 垂直居中 */
  font-weight: bold;
  color: black;
  border-right: #2c3e50;
  border: none;
  border-radius: 5px;
  margin-top: 5px;
  text-align: center;
  //border: black 1px solid;
}
.secondery1 li {
  width: 100%; /* 填充父元素宽度 */
  /* 其他样式属性... */
}
.news1 {
  width: 100%;
  overflow-x: hidden;
}
.downLoad:hover {
  background: var(--main-color);
  color: #fff;

  img.pdfIcon {
    display: none;
  }

  img.pdfIconHover {
    display: block;
  }
}

.paperB {
  // margin: 10px 0;
}

.paperBox {
  margin-top: 20px;
  display: flex;
  height: 184px;
  overflow: hidden;
  // margin: 0 15px 10px 0;
  // background: #f2f7ff;
}

.paperT1 {
  font-size: 20px;
  color: #262626;
  letter-spacing: 0;
  line-height: 28px;
  height: 52px;
  overflow: hidden;
  font-weight: 700;
  transition: all 0.3s;
  &:hover{
    color: var(--main-color);
  }
}

.paperT2 {
  margin-top: 10px;
  font-size: 14px;
  color: #8a9098;
  line-height: 22px;
  height: 44px;
  overflow: hidden;
}

.paperPage {
  margin-top: 10px;
  font-size: 14px;
  color: #8a9098;
  line-height: 20px;
}

.paperLook {
  width: 180px;
  height: 40px;
  background: #1a79ff;
  border-radius: 2px;
  font-size: 16px;
  color: #fff;
  line-height: 40px;
  text-align: center;
  margin-top: 20px;
}

.more {
  display: inline-block;
  height: 40px;
  font-size: 14px;
  color: #a1a8b3;
  line-height: 40px;
  border: 1px solid #a1a8b3;
  border-radius: 2px;
  transition: all 0.3s;
  cursor: pointer;
  padding: 0 68px;
}

.paperMa {
  position: relative;
  width: 570px;
  height: 215px;
  overflow: hidden;
  margin: 10px 10px 10px 0;
}

.paperMBg {
  position: absolute;
  left: 20px;
  top: 0;
  width: 146px;
  height: 196px;
  overflow: hidden;
  border-radius: 2px;
}

.paperMBox {
  width: 100%;
  height: 185px;
  overflow: hidden;
  margin-top: 30px;
  padding-left: 186px;
  box-sizing: border-box;
  background: #f2f7ff;
  border-radius: 2px;
  padding-right: 50px;
}

.paperMBoxT1 {
  max-height: 48px;
  overflow: hidden;
  margin-top: 40px;
  font-size: 16px;
  font-weight: 700;
  color: #262626;
  line-height: 24px;
}

.paperMBoxT2 {
  margin-top: 10px;
  max-height: 66px;
  overflow-y: auto;
  font-size: 14px;
  color: #8a9099;
  line-height: 22px;
}

::v-deep .el-tabs__item {
  font-size: 16px;
  font-weight: 700;
}

::v-deep .el-tabs__item:hover {
  color: #1a79ff !important;
}

::v-deep .el-tabs__item.is-active {
  font-size: 16px;
  font-weight: 700;
  color: #1a79ff !important;
}

.el-button--mini {
  height: 20px;
  color: #a1a8b3;
  background-color: #f2f7ff;
}

.el-pagination {
  text-align: center;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #409eff !important;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #1a79ff !important;
}

// ::v-deep .el-pagination.is-background .el-pager li{
//   background: #fff !important;
//   border: 1px solid #333;
// }

// ::v-deep .el-pagination.is-background .btn-prev{
//   background: #fff !important;
// }
// ::v-deep .el-pagination.is-background .btn-next {
//   background: #fff !important;
// }

.label-box {
  display: inline-block;
  height: 20px;
  padding: 0 12px;
  font-size: 12px;
  line-height: 20px;
  color: #a1a8b3;
  margin-right: 10px;
  background-color: #f2f7ff;
  border-radius: 10px;
  transition: all 0.3s;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    color: #fff;
    background-color: var(--main-color);
  }
}

// 移动端
.chineseBox {
  display: flex;
  &:first-child{
    padding-top: 16px;
  }
}
.chineseR {
  width: 100%;
  // height: 120px;
  overflow: hidden;
  border-radius: 4px;
  padding: 0 0 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
  img {
    width: 90px;
    height: 120px;
    margin-right: 10px;
  }
  & > div {
    // padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    & > div{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .chinesePage{
    padding-left: 0;
    line-height: initial;
    background: none;
  }
}

.reportTitle {
  font-size: 14px;
  color: #17181a;
  text-align: left;
  line-height: 24px;
  font-weight: bold;
  width: 100%;
  height: 50px;
  overflow: hidden;
  overflow: hidden; //超出盒子宽度内容，便溢出隐藏
  text-overflow: ellipsis; //用...省略号显示
  display: -webkit-box; //将对象作为弹性伸缩盒子模型显示
  -webkit-box-orient: vertical; //从上到下垂直排列子元素
  -webkit-line-clamp: 2; //显示的行数
}

.chinesePage {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAA0CAYAAAD8H6qwAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAKqADAAQAAAABAAAANAAAAAALyozoAAAB2UlEQVRoBe2ZvU7DMBDHzykb37DCOyBAIIFYkSisiEpUYuOh2KCtVFrGNm1VJiYehDdAbLUPuzSRZRpiJ8F1wVnqO1v2r/87XxKH1NvhGWN4h4hbYPEiQD5KBMrXV+cvOssGs4AUYAi4SBHCWrt/ogVqW0kZSsAiG4X1x/BY9k9rB9OcNn2IsESB9WrtztFP65L7ZgflATeVCyLbRbfV9eL5CbyXMDitVsqvsU9qzFzRmAVhmQEOHp66h7FPargDyqF4zq4AhUGj1T+QGMdNp0AFEd/cqyMcCWX3ZVjnQMdwCGtIcdhodfYiWDdBBR2HpQyGtWZ/V5jugo5ZYZ0RypXt7iwIWqcvxA0K8GxdUULIm6kw/KawaR00CMhtFljroa9elntc0e00VdU7mHVF0wCT+j1okjJZ/cY5mveNQGwksaEmuarNbRz6vG8E4kFdzKFNOBloDGq6QFHjjUGz1sEIOAp9ZOv+Gueobh3UBdAdZ6yo7sRFj/OgRStqnKO+jqaE4O/mqK+j/zb0KX/817rnZjP5OpqUA/55VFXG11FVEcWem13vQZXI5Ta9orklVCbwiiqC5Da9orklVCb49i1U6XfGnJ/QZzn4ty3z18Faxq8UtmCj079Pe7/MwOy4YJoAAAAASUVORK5CYII=)
    no-repeat left 10px;
  background-size: 8px 11px;
  font-size: 12px;
  color: #a1a8b3;
  line-height: 35px;
  padding-left: 12px;
}

.chineseButton {
  width: 56px;
  height: 24px;
  background: #1a79ff;
  border-radius: 0.06rem;
  font-size: 12px;
  color: #fff;
  text-align: center;
  line-height: 24px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    border: 1px solid;
    padding: 5px 20px;
  }
  button {
    margin: 0 10px;
    padding: 5px 20px;
    background: #fff;
  }
}

.news1 ::v-deep .el-tabs__header{
  margin: 0;
}
</style>

